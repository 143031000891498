import { SessionStorage } from "../utils/SessionStorage";

const API_URL = process.env.REACT_APP_API_URL;

export class ServicesData{
  
  static getComunero(comunero_id){   
    let url  =API_URL+"comuneros/" + comunero_id;    
    return fetch(url).then((response) => response.json());  
  }

  static getNextComunero(comunero_id){   
    let url  =API_URL+"comuneros/next?" + new URLSearchParams({ id:comunero_id });    
    return fetch(url).then((response) => response.json());  
  }

  static getPrevComunero(comunero_id){
    let url  =API_URL+"comuneros/prev?" + new URLSearchParams({ id:comunero_id });    
    return fetch(url).then((response) => response.json());
  }

  static updateComunero(comunero_id,comunero){   
    let url  =API_URL+"comuneros/" + comunero_id;    
    return fetch(url, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({nombre:comunero.nombre})
    })
    .then((response) => response.json());  
  }

  static deleteComunero(comunero_id){   
    let url  =API_URL+"comuneros/" + comunero_id;    
    return fetch(url, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
    })
    .then((response) => response.json());  
  }

  static getLecturas(contador_id, anno){
    let url  =API_URL+"lecturas/" + contador_id +"/"+anno;
    return fetch(url).then((response) => response.json());
  }

  static saveData(contador_id, ejercicio, mes, dato, notas){
    let url  =API_URL+"lecturas/" + contador_id;
    return  fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ejercicio, mes, dato, notas})
    })
    .then((response) => response.status==200);
  }

  static getComuneros(){
    let url  =API_URL+"comuneros";
    return fetch(url).then((response) => response.json());
  }

  static updateContador(contador_id,contador){   
    let url  =API_URL+"contadores/" + contador_id;    
    return fetch(url, {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({id:contador.id,
        observaciones: contador.observaciones})
    })
    .then((response) => response.json());  
  }

  static createContador(comunero_id,contador){   
    let url  =API_URL+"contadores";    
    return fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
            comunero_id: comunero_id,
            id: contador.id,
            observaciones: contador.observaciones})
    })
    .then((response) => response.json());  
  }

  static createComunero(comunero){   
    let url  =API_URL+"comuneros";    
    return fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
            nombre: comunero.nombre})
    })
    .then((response) => response.json());  
  }

  static getContador(contador_id){   
    let url  =API_URL+"contadores/" + contador_id;    
    return fetch(url).then((response) => response.json());  
  }

  static deleteContador(contador_id){   
    let url  =API_URL+"contadores/" + contador_id;    
    return fetch(url, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
    })
    .then((response) => response.json());  
  }
  static getContadores(){
    let url  =API_URL+"contadores ";
    return fetch(url).then((response) => response.json());
  }

  //cerrar el mes
  static closeMes(){
    let url  =API_URL+"cierre";
    return  fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'}
    }).then((response) => response.status==200 ? response.json() : false);
  }

  //Contadores pendientes
  static getPendientes(){
    let url  =API_URL+"cierrespdte";    
    return fetch(url).then((response) => response.json());
  }
 
}