import 'antd/dist/reset.css';
import React, { useEffect, useState } from 'react';
import { Space, Table, Button, Card, message, Input, InputNumber, Select  } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import moment                                 from "moment";
import 'moment/locale/es';
import _                                      from 'lodash';
import { ServicesData }       from "../services/ServicesData";
import { SessionStorage } from "../utils/SessionStorage";

moment.locale('es');

const { Option } = Select;
const API_URL = process.env.REACT_APP_API_URL;
const annos = [moment().year(), moment().year()-1]

export const Lecturas = () => {

  ////////
  // Hooks
  ////////
  const [comunero, setComunero] = useState({});
  const [contador, setContador] = useState(-1);
  const [lecturas, setLecturas] = useState([]);
  const [comuneros, setComuneros] = useState([]);
  const [contadores, setContadores] = useState([]);
  const [ejercicio, setEjercicio] = useState(moment().year());
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [notas, setNotas] = useState("");

  useEffect(() => {
    document.title = "Regantes - Lecturas";
    getNext();
    getComuneros();
    getContadores();
  }, []);

  const case_columns = [
    {
      title: 'Mes',
      dataIndex: 'mes',
      key: 'mes',
      className: 'celdaTablaLEctura',
      render: (v) => moment().month(v-1).format("MMMM").toUpperCase()
    },
    {
      title: 'Lectura',
      dataIndex: 'valor',
      key: 'valor',
      className: 'celdaTablaLEctura',
      render: (v, datos) => {
        let mes = SessionStorage.getItem('user_info').mes;
        let anio = SessionStorage.getItem('user_info').ejercicio;
        return (anio === ejercicio) && (mes === datos.mes)
          ? <><Space.Compact block size="small" >
              <InputNumber style={{width:'100%'}} defaultValue={v} onChange={(d) => setDato(datos.mes, d)}></InputNumber>
              <Button type="primary" icon={<SaveOutlined />} disabled={datos.nuevo===datos.valor} onClick={(d) => {saveData(datos.mes)}}/>            
            </Space.Compact>
            <Input style={{width:'100%'}} placeholder='Notas' value={notas} onChange={(d) => setNotas(d.target.value)}></Input>
            </>
          : v
      },
    }
  ];

  const getNext = async() => {
    setPrevDisabled(true);
    setNextDisabled(true);
    if (contador == -1 || contador == comunero.contadores.length-1){
      ServicesData.getNextComunero(comunero.id)
      .then((com) => {
        setComunero(com);
        setContador(0);
        setNextDisabled(false);
        getLecturas(com.contadores[0].id, ejercicio);
      }).catch((error) => {});
    }else{
      getLecturas(comunero.contadores[contador+1].id, ejercicio);
      setContador(contador+1)
      setNextDisabled(false);
    }
    setPrevDisabled(false);
  }

  const getPrev = async() => {
    setPrevDisabled(true);
    setNextDisabled(true);
    if (contador <= 0){
      ServicesData.getPrevComunero(comunero.id)
      .then((com) => {
        setComunero(com);
        setContador(com.contadores.length-1);
        getLecturas(com.contadores[com.contadores.length-1].id, ejercicio);
        setPrevDisabled(false);
      }).catch((error) => {});
    }else{
      getLecturas(comunero.contadores[contador-1].id, ejercicio);
      setContador(contador-1);
      setPrevDisabled(false);
    }
    setNextDisabled(false);
  }

  const changeEjercicio = async(anno) =>{
    setEjercicio(anno);
    getLecturas(comunero.contadores[contador].id, anno)
  }

  const getLecturas = async(id, anno) => {
    setLecturas([]);
    setNotas("")
    let mes = SessionStorage.getItem('user_info').mes;
    let anio = SessionStorage.getItem('user_info').ejercicio;
        
    ServicesData.getLecturas(id,anno)
    .then((cont) => {
      let procesadas = _.map([1,2,3,4,5,6,7,8,9,10,11,12], m=>{
        let dato = _.find(cont, l => l.mes === m);
        if (!dato){
          dato = {mes:m, valor:null, nuevo:null, observaciones:null}
        }
        if (dato.ejercicio === anio && mes === dato.mes)
          setNotas(dato.observaciones)
        
        dato.nuevo = dato.valor;
        return dato;
      });
      setLecturas(procesadas);
    }).catch((error) => {});
  }

  const setDato = async(mes, valor) => {
    lecturas[mes-1].nuevo = valor
    setLecturas(_.clone(lecturas))
  }

  const saveData = async(mes) =>{
    ServicesData.saveData(
        comunero.contadores[contador].id, 
        ejercicio, 
        mes, 
        lecturas[mes-1].nuevo,
        notas)        
    .then((result) => {
      if(result){
        lecturas[mes-1].valor = lecturas[mes-1].nuevo
        setLecturas(_.clone(lecturas))
        comunero.contadores[contador].lectura = lecturas[mes-1].nuevo
        setComunero(_.clone(comunero))
      }else{
        messageApi.open({
          type: 'error',
          content: 'El valor indicado no es válido. Compruebe que es mayor que el actual.',
        });  
      }
    });
  }

  const getComuneros = async() => {
    ServicesData.getComuneros()
    .then((comuneros) =>{
      setComuneros(comuneros.map(comunero =>({
        value: comunero.id,
        label: comunero.nombre
      })));
    }).catch((error) => {});
  }

  const getContadores = async() => {
    ServicesData.getContadores()
    .then((contadores) =>{
      setContadores(contadores.map(contador =>({
        value: contador.id,
        label: contador.comunero_id
      })));
    }).catch((error) => {});
  }

  const getComunero = async(id) => {
    ServicesData.getComunero(id)
      .then((com) => {
        setComunero(com);
        setContador(0);
        getLecturas(com.contadores[0].id, ejercicio);
      }).catch((error) => {});
  }

  const getContador = async(value) => {
    ServicesData.getComunero(_.find(contadores, c=> c.value == value.value).label)
      .then((com) => {
        setComunero(com);
        setContador(_.findIndex(com.contadores, c =>c.id === value.label));
        getLecturas(value.label, ejercicio);
      }).catch((error) => {});
  }

  return (
  <>
    {contextHolder}
    <Space direction="vertical" style={{width:'100%'}}>
      <Card size="small">
        <Space direction="vertical" style={{width:'100%'}}>
          <Space.Compact block size="middle" >
            <Button type="primary" disabled={prevDisabled} onClick={() => getPrev()}>Anterior</Button>
            <Select defaultValue={annos[0]} style={{width:'100%'}} onChange={changeEjercicio}>
              <Option value={annos[0]}>Lecturas anuales {annos[0]}</Option>
              <Option value={annos[1]}>Lecturas anuales {annos[1]}</Option>
            </Select>
            <Button type="primary" disabled={nextDisabled} onClick={() => getNext()}>Siguiente</Button>
          </Space.Compact>
          <Space.Compact block size='middle'>
            <Button disabled={true} style={{paddingInline:4}}>Comunero</Button>
            <Select
              showSearch
              placeholder="Selecciona un comunero"
              filterOption={(input, option) =>
                (option?.label+"" ?? '').toLowerCase().includes(input.toLowerCase()) ||
                (option?.value+"" ?? '') == input
              }
              options={comuneros}
              value={comunero.nombre}
              onSelect={getComunero}
              style={{width:'100%'}}
            />
            <Button disabled={true} style={{paddingInline:4}}>{comunero.id}</Button>
          </Space.Compact>
          <Space.Compact block size='middle'>
            <Button disabled={true} style={{paddingInline:4}}>Contador</Button>
            <Select
              showSearch
              placeholder="Selecciona un contador"
              filterOption={(input, option) =>{ 
                return (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
              }}
              options={contadores}
              labelInValue={true}
              fieldNames ={{ label: "value", value: "value"}}
              value={contador>=0 && comunero.contadores ? comunero.contadores[contador]?.id :""}
              onSelect={getContador}
              style={{width:'100%'}}
            />
            <Button disabled={true} style={{paddingInline:4}}>{contador>=0 && comunero.contadores ? comunero.contadores[contador].lectura : ""}</Button>
          </Space.Compact>
          <div>{contador>=0 && comunero.contadores ? comunero.contadores[contador]?.observaciones : ""}</div>          
        </Space>
      </Card>
      <Card size="small">
        <Table size="small" dataSource={lecturas} columns={case_columns} bordered pagination={false}/>
      </Card>
    </Space>    
  </>
)
}