import { useContext }                   from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContext }                  from "../auth/AuthContext";
import { Contadores }       from "../components/Contadores";
import { Comuneros }              from "../components/Comuneros";
import { MenuPage }                     from "../components/MenuPage/MenuPage";
import { Lecturas }                         from "../components/Lecturas";
import { WithoutMenu }                  from "../components/WithoutMenu";
import { ProtectedAnyRoute }            from "./ProtectedAnyRoute";
import { ProtectedLoginPage }           from "./ProtectedLoginPage";
import { ProtectedRoute }               from "./ProtectedRoute";
import { Administracion } from "../components/Administracion";

export const AppRouter = () => {
  const {logged} = useContext(AuthContext)

  return (
    <BrowserRouter>
      <Routes>
        {/* Not logged */}
        <Route path="/login" element={<ProtectedLoginPage/>}/>
        <Route path="/" element={<ProtectedAnyRoute/>}/>

        {/* Logged */}
        {/* This conditional is to fix a bad effect UI */}
        {logged && (
        <Route element={<MenuPage/>}>
          <Route path="/comuneros" element={<ProtectedRoute path="/comuneros"><Comuneros/></ProtectedRoute>}/>
          <Route path="/contadores" element={<ProtectedRoute path="/contadores"><Contadores/></ProtectedRoute>}/>
          <Route path="/lecturas" element={<ProtectedRoute path="/lecturas"><Lecturas/></ProtectedRoute>}/>
          <Route path="/administracion" element={<ProtectedRoute path="/administracion"><Administracion/></ProtectedRoute>}/>
          <Route path="/without-menu" element={<ProtectedRoute path="/without-menu"><WithoutMenu/></ProtectedRoute>}/>
        </Route>
        )}

        {/* Any route */}
        <Route path="*" element={<ProtectedAnyRoute/>}/>
      </Routes>
    </BrowserRouter>
  );
}