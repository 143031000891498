import { useContext, useEffect } from "react";
import { AuthContext }          from "../auth/AuthContext";
import { Alert, Button, Input, Row, Col, Card, Form } from 'antd';

export const LoginPage = () => {
  ////////
  // Hooks
  ////////
  const {status, login, errorMessage} = useContext(AuthContext);
  
  useEffect(() => {
    // Update title
    document.title = "Regantes - Login";
  }, []);


  ////////////
  // Functions
  ////////////
  const onFinish = (values) => {
    login(values);
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="App">
              <Card 
                style={{ margin: 'auto', width: '80%' }}
                type="inner"
                title={<img src='logo_letras_transparente.png' width={'100px'}></img>}>
                    
                <Form name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item label="Usuario" name="usuario"
                    rules={[{ required: true, message: 'Introduzca un usuario!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item label="Contraseña" name="password"
                    rules={[{ required: true, message: 'Introduzca la contraseña!' }]}
                  >
                    <Input.Password />
                  </Form.Item>

                  {status === "error" && <Alert message={errorMessage} type="error" showIcon style={{ marginBottom: 20 }}  />}


                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Aceptar
                    </Button>
                  </Form.Item>
                </Form>
                
              </Card>
    </div>
  );
}