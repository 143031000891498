import 'antd/dist/reset.css';
import React, { useEffect, useRef, useState } from 'react';
import { Space, Table, message, Button, Input, Form, Modal, ConfigProvider, FloatButton, Popconfirm} from 'antd';
import { SearchOutlined, SaveOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Highlighter                            from 'react-highlight-words';
import locale from "antd/es/locale/es_ES";
import _                                      from 'lodash';
import { ServicesData }       from "../services/ServicesData";

const stateInitial = {
  openEditModalState: false,
  openCreateModalState: false,
  selectedRow: {},
}

export const Contadores = () => {

  ////////
  // Hooks
  ////////
  const [state, setState] = useState(stateInitial);
  const [contadores, setContadores] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleSearch = (
    selectedKeys, confirm, dataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  
  const getColumnSearchProps = (dataIndex, dataText) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetear
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys)[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex] 
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const case_columns = [
    {
      title: {},
      dataIndex: 'id',
      key: 'id',
      width: 150,
      render: (value, all) => 
        <Space align='horizontal'>
          <Button size='small' primary onClick={() => handleOpenEdit(all)} icon={<SaveOutlined />} />
          <Popconfirm title="¿Quiere eliminar el contador?"
            onConfirm={() => {
              ServicesData.getContador(value)
              .then((contador) => {
                if (contador.lectura >0){
                  messageApi.open({
                    type: 'error',
                    content: 'No se puede eliminar un contador que tenga lectura. Contacte con administrador',
                  }); 
                }else{
                  ServicesData.deleteContador(contador.id).then(() => getContadores())
                }
  
              });          
            }}
            onCancel={() => {}}
            okText="Si"
            cancelText="No"
          >
            <Button size='small' danger icon={<DeleteOutlined />} />
          </Popconfirm>
          
        </Space>
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 150,
      sorter: (a, b) => a > b ? 1 : -1
    },   
    {
      title: 'Observaciones',
      dataIndex: 'observaciones',
      key: 'observaciones',
      ...getColumnSearchProps('observaciones','observaciones'),
    },
    {
      title: 'Lectura',
      dataIndex: 'lectura',
      key: 'lectura',
      ...getColumnSearchProps('lectura','lectura'),
    },
    {
      title: 'Comunero',
      dataIndex: 'comunero_id',
      key: 'comunero_id',
      ...getColumnSearchProps('comunero_id','comunero'),
    }
  ];

  useEffect(() => {
    document.title = "Regantes - Contadores";
    getContadores();
  }, []);

  /**
   * 
   * @param {object} values values.fecha of type moment, values.estado of type boolean
   */
  const getContadores = async() => {
    setContadores([]);
    ServicesData.getContadores()
    .then((data) => setContadores(data));      
  }

  const handleOpenEdit = (all) => {
    ServicesData.getContador(all.id)
    .then((data) => {
      form.setFieldsValue(data)
      setState(prevState => ({
        ...prevState,
        openEditModalState: true,
        selectedRow: data
      }));  
    });
  }

  const handleOpenCreate = () => {
    form.setFieldsValue({id:"",observaciones:"",comunero_id:""});
    setState(prevState => ({
      ...prevState,
      openCreateModalState: true,
      selectedRow :{}
    }));
  }

  const handleCreate = () => {
    let formulario = form.getFieldsValue();
    ServicesData.createContador(formulario.comunero_id,formulario)
    .then(response =>{
      getContadores();
      handleCloseModalState();
    })  
  }

  const handleCloseModalState = () => {
    setState(prevState => ({
      ...prevState,
      openEditModalState: false,
      openCreateModalState: false,
      selectedRow: {}
    }));
  }

  const handleSave = (datos) => {
      let formulario = form.getFieldsValue();
      ServicesData.updateContador(datos.id,formulario)
      .then(response =>{
        getContadores();
        handleCloseModalState();
      })
  }

  return (
  <ConfigProvider locale={locale}>
    {contextHolder}
    <FloatButton onClick={() => handleOpenCreate()}
      shape="circle" type='primary' icon={<SaveOutlined/>}
    />
    <div style={{ backgroundColor: "#FFF", overflow: "auto" }}>
      <Table dataSource={contadores} columns={case_columns} bordered pagination={{ pageSize: 50 }}/>
    </div>

    {/* Modals */}
    <Modal
      title={"Contador "+state.selectedRow.id}
      open={state.openEditModalState}
      onCancel={handleCloseModalState}
      footer={[
        <Button onClick={handleCloseModalState}>
          Cancelar
        </Button>,
        <Button type="primary" onClick={() => handleSave(state.selectedRow)}>
          Guardar
        </Button>
      ]}
    >
      
      <Form
        layout='horizontal'
        labelCol={{span:6}}
        wrapperCol={{span:20}}
        form={form}
        autoComplete="off"
        size='small'
      >
      <Form.Item label="Id" name="id" rules={[{ required: true}]} size='small'>
        <Input />
      </Form.Item>
      <Form.Item label="Observaciones" name="observaciones" rules={[{ required: true}]} size='small'>
        <Input />
      </Form.Item>

      <Form.Item label="Lectura" name="lectura" rules={[{ required: true}]} size='small'>
        <Input disabled/>
      </Form.Item>

      <Form.Item label="Comunero" name="comunero_id" rules={[{ required: true}]} size='small'>
        <Input disabled/>
      </Form.Item>

    </Form>
    </Modal>

    <Modal
      title={"Crear Contador"}
      open={state.openCreateModalState}
      onCancel={handleCloseModalState}
      footer={[
        <Button onClick={handleCloseModalState}>
          Cancelar
        </Button>,
        <Button type="primary" onClick={() => handleCreate()}>
          Guardar
        </Button>
      ]}
    >
      <Form
        layout='horizontal'
        labelCol={{span:6}}
        wrapperCol={{span:20}}
        form={form}
        autoComplete="off"
        size='small'
      >
      <Form.Item label="Id" name="id" rules={[{ required: true}]} size='small'>
        <Input />
      </Form.Item>
      <Form.Item label="Observaciones" name="observaciones" rules={[{ required: true}]} size='small'>
        <Input />
      </Form.Item>
      <Form.Item label="Comunero" name="comunero_id" rules={[{ required: true}]} size='small'>
        <Input />
      </Form.Item>
    </Form>
    </Modal>
  </ConfigProvider>
)
}