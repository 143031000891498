import { useEffect } from "react";

export const WithoutMenu = () => {
  ////////
  // Hooks
  ////////
  useEffect(() => {
    // Update title
    document.title = "Icot - Sin menú";
  }, []);

  return (
    <div className="ycs-card" style={{ boxSizing: "border-box", textAlign: "center", padding: "10px 0", marginTop: 5 }}>
      Contacte con su administrador para que le asigne permisos de navegación
    </div>
  );
}